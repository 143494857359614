import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardImg, CardText, Label, Input, CardTitle, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Media, Alert, TabContent, NavLink, Nav, NavItem, TabPane } from 'reactstrap';
import Axios from '../../context/axios';
import { Questions } from "./questions";
import Spinner from '../Spinner';
import { FiArrowLeft, FiBox, FiFilm, FiCopy, FiBookOpen, FiVideo, FiPaperclip, FiCamera, FiAlertTriangle, FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { MdAddShoppingCart } from "react-icons/md";
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import './productcustom.css';

export default function ProductDetails() {
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [sku, setSku] = useState([]);
    const [selsku, setSelSku] = useState([]);
    const [images, setImages] = useState([]);
    const [availablemedia, setAvailableMedia] = useState([]);
    const [availableInfo, setAvailableInfo] = useState([]);
    const [relatedproducts, setRelatedproducts] = useState([]);
    const [stringsku, setStringSku] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeTab, setActiveTab] = useState('1');
    const [animating, setAnimating] = useState(false);

    const safetyFiltered = data => {
        return data.mediaType === 9;
    }

    const safetyMedia = availablemedia.filter(safetyFiltered);

    useEffect(() => {
        async function init(id) {
            try {

                let getUrl = `/api/product/${id}`;
                let ud = (JSON.parse(sessionStorage.getItem("tokens")) === null ? "" : JSON.parse(sessionStorage.getItem("tokens")).jwttoken);
                const xd = (JSON.parse(sessionStorage.getItem("tokens")) === null ? "" : JSON.parse(sessionStorage.getItem("tokens")).username);
                let axiosConfig = { headers: { "Authorization": `Bearer ${ud}`, "Username": `${xd}` } };
                const response = await Axios.get(getUrl, axiosConfig);
                
                if (response.status === 200) {
                    setProduct(response.data);
                    setSku(response.data.skus);
                    let skuid = response.data.defaultSku;
                    setStringSku(skuid);
                    // find current sku Desc and Prod Descr
                    let findsku = response.data.skus.find(sk => sk.skuid === Number(skuid));
                    setSelSku(findsku);
                    setImages(response.data.images);
                    setAvailableMedia(response.data.relatedMedia);
                    setRelatedproducts(response.data.relatedProducts);
                    setAvailableInfo(response.data.largeDescription);
                    console.log(response.data);
                } else {
                    throw response;
                }
            } finally {
                setLoading(false);
            }
        }
        init(id, 0);
    }, [id]);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      }
    
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }
    
    const imageSlides = images.map((m) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={m}
                
            >
                <img src={m} className="carousselcard" width="400" height="300" alt={m._id} />
            </CarouselItem>
        );
    });

    //key={m._id}

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    function setItemCount(addcnt) {
        let itemCount = 0;
        let itemAddCount = (isNaN(addcnt) ? 0 : parseInt(addcnt));
        itemCount = parseInt(localStorage.getItem("cartitems"));
        if (isNaN(itemCount)) {
            localStorage.setItem("cartitems", itemAddCount);
        }
        else {
            itemCount += itemAddCount;
            localStorage.setItem("cartitems", itemCount);
        }
    }

    function handleAddToCartChange(e) {
        e.persist(); // persist the event
        setQuantity(e.target.value);
        return { quantity };
    }

   
    function onChangedCombo(e) {
        let findsku = sku.find(sk => sk.skuid === Number(e));
        setSelSku(findsku);
    }
   

    async function AddToCart(e) {
        e.preventDefault();
        let iskuid = (isNaN(stringsku) ? 0 : Number(stringsku));
        var data = { productId: Number(product.id), skuId: Number(iskuid), SessionId: sessionStorage.sessionId, quantity: Number(quantity), action: "A" };
        let prodName = product.name;
        let qty = (quantity != null ? quantity : Number(1));
        
        let result = await Axios.post('/api/cart', data);
        if (result.status === 200) {

            sessionStorage.sessionId = result.data.sessionId;
            toast.info(`${qty}x ${prodName} toegevoegd`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
            setItemCount(qty);
        } else {
            toast.warning(`Product niet toegevoegd!`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, });
        }

    }

    if (loading) return <Spinner />;

    return (
        <div>
        <Col id="colrefresh" className="colcontainer">
                {(product.promotion === 1) &&
                    <Alert id="promo" color="warning"><b>Aanbiedingsartikel, prijzen zijn exclusief BTW en zolang de voorraad strekt.</b></Alert>
                }
                <Link to="/products" style={{ textDecoration: 'none' }}>
                    <Row className="colcontainer"><h4><FiArrowLeft />{" "}Terug naar Productenlijst</h4></Row>
                </Link>

                
            <Row>
                <Col>
                    <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    className = "productcarousselcard"
                    >
                    <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
                    {imageSlides}
                    <CarouselControl class="filter-carroussel" direction="prev" directionText=" " onClickHandler={previous} />
                    <CarouselControl class="filter-carroussel" direction="next" directionText=" " onClickHandler={next} />
                    </Carousel>
                    
                </Col>
                <Col>
                    <h2>{product.name}</h2>
                    <p><b>Omschrijving</b>{" : "}{product.description}</p>
                    <p><b>Categorie</b>{" : "}{product.category}</p>
                    <p><b>Merk</b>{" : "}{product.brand}</p>
                    {(product.inStock === 1) && <p><b>Voorradig</b>{" : "}Ja <FiThumbsUp style={{ color: 'green' }} /></p> }
                    {(product.inStock !== 1) && <p><b>Voorradig</b>{" : "}Nee <FiThumbsDown style={{ color: 'orange' }}/> Levertijd kan langer zijn.</p> }
                    {(product.pckaging === 1) && <p><b>Verpakking</b>{" : "}<FiBox /> Doos</p> }
                    {(product.packaging === 2) && <p><b>Verpakking</b>{" : "}<FiBox /> Baal</p> }
                    {(product.packaging === 3) && <p><b>Verpakking</b>{" : "}<FiBox /> Pallet</p> }
                    {(product.packaging === 4) && <p><b>Verpakking</b>{" : "}<FiBox /> Stuk</p> }
                    {(product.packaging === 0) && <p><b>Verpakking</b>{" : "}<FiBox /> Fles</p>}
                        {(sku.length > 1) ?
                        <span>
                        <Label for="skuselector"><b>Kies uw versie:</b></Label>
                                <Input type="select" name="skuselector" id="skuselector" onChange={(event) => {
                                    onChangedCombo(event.target.value); setStringSku(event.target.value); console.log(event.target.value); 
                                }}>
                                {sku.map((s) => (<option key={s.skuid} value={s.skuid}>{product.name}{" "}{s.skuProductDescription}</option>))}
                                </Input>
                                
                        </span>
                        :
                        <span>
                            <Label for="skuselector"><b>SKU:</b></Label>
                                <Input type="select" name="skuselector" id="skuselector" disabled={true}>
                            {sku.map((s) => (
                                <option key={s.skuid} value={s.skuid}>{product.name}{" "}{s.skuProductDescription}</option>))}
                         </Input>
                        </span>
                        }
                        <br />
                        <p><b>Prijs per pakking</b>{" : "}{selsku.skuDescription}</p>
                    <br />
                    <div>
                        {(product.active === 1) ?
                            <Row>
                                <Col sm={3} >
                                    <input type="number" className="form-control col-sm-2 mr-2" onChange={handleAddToCartChange} value={quantity} id="quantity" />
                                </Col>
                                <Col sm={4}>
                                    <button className="btn btn-primary form-control col-sm-3" onClick={(e) => { AddToCart(e); }}><MdAddShoppingCart />{" "}Toevoegen</button>
                                </Col>
                                
                            </Row>
                            :
                            <Row>
                                <Col >
                                <p ><b>Dit product is niet (meer) actief. Excuses voor het ongemak.</b></p>
                                </Col>                                
                            </Row>
                        }
                    </div>
                    <br />
                </Col>
                <hr />
                <ToastContainer position="top-right" autoClose={500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} />
            </Row>
        </Col>
        <Col className="colcontainer">
            <center><p><i>Prijzen aangegeven op de TOPO website zijn altijd exclusief BTW.<br />Op zoek naar net iets anders? Neem contact op voor andere mogelijke product configuraties.</i></p></center>
            <br />            
            <Nav tabs className="navtabsleft">
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} ><b><FiBookOpen />{" "}Product informatie</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }} ><b><FiCopy />{" "}Gerelateerde producten</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }} ><b><FiFilm />{" "}Instructie informatie</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} ><b><FiAlertTriangle style={{ color: 'red' }} />{" "}Veiligheidsblad</b></NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="colcontainer">
                    <Row>
                        {(availableInfo.length > 0) ?
                            <Col>
                                    {availableInfo.map((ai) => (
                                        <Row key={ai.sequence}>
                                            {(ai.displayType === 0) && <br />}
                                            {(ai.displayType === 1) && <h6><b>{ai.infoText}</b></h6>}
                                            {(ai.displayType === 2) && <p>{ai.infoText}</p>}
                                            {(ai.displayType === 3) && <h6>{ai.infoText}</h6>}
                                            {(ai.displayType === 4) && <p><b>{ai.infoText}</b></p>}
                                            {(ai.displayType === 5) && <p>{" - "}{ai.infoText}</p>}
                                            {(ai.displayType === 6) && <p><i>{ai.infoText}</i></p>}
                                            {(ai.displayType === 20) && <Media object src={ai.infoImage} alt="img" />}
                                        </Row>
                                    ))}
                            </Col>
                            :
                            <Col>
                                <Row>
                                        <h6>Er is hier geen extra product informatie beschikbaar.</h6>
                                </Row>
                            </Col>
                        }
                    </Row>
                </TabPane>
                <TabPane tabId="2" className="colcontainer">
                    <Row>
                        {(relatedproducts.length > 0) ?
                                <Col>
                                    <h6>Producten die u in combinatie met of als vervanger voor dit product kunt gebruiken.</h6>

                                    <Row>
                                    {relatedproducts.map((rp) => (
                                        <Card key={rp.id} className="productcard">
                                            <CardBody >
                                            <Link to={`/product/${rp.relatedId}`} style={{ textDecoration: 'none', color: "black" }}>
                                                    <CardImg top width="100%" center="true" className="product-img" src={rp.relationImage} alt="Card image cap" />
                                                    <CardTitle><b>{rp.relationName}</b></CardTitle>
                                                    <CardText color="black">{rp.relationDescription}</CardText>
                                            </Link>
                                            </CardBody>
                                        </Card>
                                    ))}
                                    
                                </Row>
                            </Col>
                            :
                            <Row><Col>
                                <h6>Er zijn geen gerelateerde producten gekoppeld aan dit product.</h6>
                            </Col></Row>

                        }
                        
                    </Row>
                </TabPane>
                <TabPane tabId="3" className="colcontainer">
                        <Row>
                            {(availablemedia.length > 0) ?
                                <Col><Row>
                                    <h6>Handige informatie beelden, videos of documenten die u meer vertellen over dit product.</h6>
                                    <table border={0} cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td><b>Media</b></td>
                                                <td><b>Omschrijving</b> (drukken op het icoon opent een nieuw tabblad)</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                availablemedia.map((am) => (
                                                    <tr key={am.id}>
                                                        <td><center>
                                                            <a href={am.mediaLocation} target="_blank" rel="nooper noreferrer">
                                                                {(am.mediaType === 0) && <FiCamera size={50} />}
                                                                {(am.mediaType === 2) && <FiVideo size={50} />}
                                                                {(am.mediaType === 1) && <FiPaperclip size={50} />}
                                                                {(am.mediaType === 9) && <FiAlertTriangle size={50} />}
                                                            </a>
                                                        </center></td>
                                                        <td>{am.mediaName}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </Row>
                                </Col>
                                :
                                <Col>
                                <Row>
                                    <h6>Er zijn geen gerelateerde instructies of media gekoppeld aan dit product.</h6>
                                </Row>
                                </Col>
                            }
                        </Row>
                    </TabPane>
                    <TabPane tabId="4" className="colcontainer">
                        <Row>
                            {(safetyMedia.length > 0) ?
                                <Col>
                                    <p><i>Drukken op het icoon of tekst opent het veiligheidsblad in een nieuw tabblad.</i></p>
                                    <table border={0} cellPadding={10}>
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                safetyMedia.map((sf) => (
                                                    <tr key={sf.id}>
                                                        <td><center>
                                                            <a href={sf.mediaLocation} target="_blank" rel="noreferrer">
                                                                <FiAlertTriangle size={50} style={{ color: 'red' }} /></a>
                                                        </center></td>
                                                        <td>
                                                            <a href={sf.mediaLocation} target="_blank" rel="noreferrer">
                                                                <h3 style={{ color: 'red' }}>{sf.mediaName}</h3>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>

                                    <br />
                                    <br />
                                    <hr />

                                </Col>
                                :
                                <Col>
                                    <Row>
                                        <h6>Er is geen veiligheidsblad gekoppeld aan dit product.</h6>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </TabPane>
            </TabContent>

        </Col>
        <Col className="colcontainer">
            <hr />
            <Questions />
        </Col>
       
        </div>
    );
}

/*
<ButtonGroup>
                            {sku.map((s) => (
                                <div key={s.skuid}>
                                    {s.promotion === 1 ?
                                        <Link to="#" style={{ textDecoration: 'none', color: "black" }}>
                                            <Button color="warning" onClick={(e) => setStringSku(s.skuid)} active={stringsku === s.skuid}>{s.skuDescription}</Button>
                                        </Link>
                                        :
                                        <Link to="#" style={{ textDecoration: 'none', color: "black" }}>
                                            <Button color="secondary" onClick={(e) => setStringSku(s.skuid)} active={stringsku === s.skuid}>{s.skuDescription}</Button>
                                        </Link>
                                    }
                            </div>
                        ))}
                    </ButtonGroup>
*/




/*
     <AddToCart productId={product.id} skuId={stringsku} productName={product.name} action="S" />
 * 
 * 
 * 
<table border={0} cellPadding={10}>
                                    <thead>
                                        <tr>
                                            <td><b>Informatie</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            availableInfo.map((ai) => (
                                                <tr key={ai.sequence}>
                                                    <td>
                                                        {(ai.displayType === 0) && <br />}
                                                        {(ai.displayType === 1) && <h6><b>{ai.infoText}</b></h6>}
                                                        {(ai.displayType === 2) && <p>{ai.infoText}</p>}
                                                        {(ai.displayType === 3) && <h6>{ai.infoText}</h6>}
                                                        {(ai.displayType === 4) && <p><b>{ai.infoText}</b></p>}
                                                        {(ai.displayType === 5) && <p>{" - "}{ai.infoKey}{" : "}{ai.infoText}</p>}
                                                        {(ai.displayType === 20) && <Media object src={ai.infoImage} alt="img" />}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
*/